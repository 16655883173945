<template>
    <Setup-Step
        @next="f => f()"
        fluid
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        step="order-process"
        title="Order Process">
        <template v-slot:body>
            <BT-Order-Cycle-Header showTrimmings highlightProcess title="Order Pipeline: Process" />

            <Setup-Block>
                <v-list dense>
                    <v-list-item><strong class="mr-2">Scope:</strong>Defined by the fulfiller for each individual customer.</v-list-item>
                    <v-list-item><strong class="mr-2">Options: </strong>Auto or Manual</v-list-item>
                </v-list>

                <v-divider class="my-4" />

                <p>
                    The company fulfilling the order can control when the order is processed any further in the pipeline.  
                    This allows for controlling what is going to be fulfilled next.
                </p>

                <v-divider class="my-4" />

                <Setup-Example>
                    Cobden Crayons LTD allow their customers to place orders online.  The company also automatically approves and fulfills those orders.  
                    Sometimes customers place orders three months in advance, but Cobden Crayons just needs to fulfill orders 3 days in advance.  
                    So they set this stage as 'Manual' and only manually process orders that need to be fulfilled in the current week.
                </Setup-Example>
                
            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Process-Setup',
    components: {
        BTOrderCycleHeader: () => import('~components/BT-Order-Cycle-Header.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        //SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>